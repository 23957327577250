/* eslint-disable no-prototype-builtins */
/**
 * Cart class that handles all cart operations
 * WARNING: be sure to call store.commit('setCart', this) AFTER any mutations to the
 * current Cart object have been made.
 */
import store from '../store';
import HttpClient from './HttpClient';
import Product from './Product';
import CartProduct from './CartProduct';
import { formatToCurrency, getFsDataObj, throttleFunction } from '../helpers';
import { useFsLoading } from './FastSpring';

const fsLoading = useFsLoading();

export default class Cart {

    constructor (fastSpring = null) {
        this.fs = fastSpring;
        this.products = {};
        this.upgrades = []; // WHY IS THIS AN ARRAY?!
        this.subtotal = 0;
        this.trialpricing = 0;
        this.discount = 0;
        this.totalItems = 0;
        this.visible = false;
        this.promoCode = null;
        this.promoProducts = [];
        this.offerTag = null;
        this.coupon = null;
        let cart = store.getters.getCart;

        if (cart && typeof cart === 'object') {
            for (let prop in cart) {
                if (prop === 'visible' || prop === 'fsData') {
                    continue;
                }

                if (prop === 'products') {
                    for (let path in cart.products) {
                        try {
                            this.products[path] = new CartProduct(path, cart.products[path]);
                        } catch (e) {
                            continue;
                        }
                    }

                    continue;
                }

                if (prop === 'upgrades') {
                    cart.upgrades = cart.upgrades.map(upgrade => {
                        try {
                            return new CartProduct(upgrade.path, upgrade);
                        } catch (e) {
                            return null;
                        }
                    }).filter(upgrade => !!upgrade);

                    continue;
                }

                this[prop] = cart[prop];
            }
        }

        store.commit('setCart', this);
    }

    /**
     * Instantiates a new cart object with API response data.
     * Avoids overwriting visible control variable & redundant fsData properties
     * @param {object} data 
     */
    instantiate (data) {
        for (let prop in data) {
            if (["visible", "fsData"].includes(prop)) {
                continue;
            }

            this[prop] = data[prop];
        }
    }

    /**
     * Attempt to apply the product's freebies.
     * 
     * Optionally, setting args allows for the specified product to be added after
     * the function determines if there are any freebie choices to be made.
     * 
     * @param {Array<Object>} freebies 
     * @param {Object} args 
     * @returns 
     */
    addFreebies (freebies, args) {
        let hasFreebies = false;

        const sequence = [];

        if (args) {
            sequence.push(() => this.addProduct(args));
        }

        if (freebies) {
            if (!Array.isArray(freebies)) {
                freebies = Object.values(freebies);
            }

            hasFreebies = freebies.length > 0;
        }

        if (!hasFreebies) {
            sequence.forEach(v => v());
            return;
        }
        
        let choiceExists = false;

        freebies.forEach(v => {   // Automatically add freebie that is not mutually exclusive with another
            if (v.choice) {
                if (args) {
                    args.clickToCheckout = false;
                }

                choiceExists = true;
                return;
            }
            
            if (getFsDataObj(store.getters.getFsData, v.path) && !this.products[v.path]) {
                sequence.push(() => this.addProduct({ path: v.path, is_freebie: true }));
            }
        });

        if (choiceExists) {     // Display choice modal if freebie is mutually exclusive with another
            const optionIsInCart = freebies.some(v => !!this.products[v.path]);
            const freebieContainsSub = this.isFreebieSub(freebies);

            // Set default value; should be true if freebies do not contain a Subscription
            let canApplyFreebie = !freebieContainsSub;

            // Need to check if Freebie should be applied given the current state of the cart
            if (!optionIsInCart && freebieContainsSub) {
                const freebieSub = Object.values(this.products).find(product => product.type === 'subscription' && product.wp_only);

                if (freebieSub) {
                    const defaultPath = freebies.find((val) => val.is_default_value)?.path;

                    if (defaultPath) {
                        const defaultFreebie = getFsDataObj(store.getters.getFsData, defaultPath);
                        canApplyFreebie = this.compareSubs(defaultFreebie, freebieSub) < 0; // New Freebie is better than current Freebie in cart
                    }
                } else {
                    canApplyFreebie = !Object.values(this.products).some(product => product.type === 'subscription' && !product.wp_only);
                }
            }

            if (!store.getters.getFreebieModalVisibilityState && !optionIsInCart && canApplyFreebie) {
                store.commit('setFreebieModalVisibilityState', freebies.filter(v => v.choice));
            }
        }

        sequence.forEach(v => v());
    }

    /**
     * Remove the product's associated freebies and, if allowed, replace the freebie.
     * Will attempt to add a bundled Subscription Freebie as appropriate if the removed product was a Trial or a Subscription
     * 
     * @param {CartProduct} removedProduct 
     * @param {Boolean} replaceFreebie 
     */
    removeFreebies (removedProduct, replaceFreebie = true) {
        const { products, upgrades } = this;

        let removedSubFreebie = false;

        if (this.checkForFreebies(removedProduct)) {
            /**
             * Determine how many products share the Freebie
             * 
             * @param {String} freebiePath 
             * @returns Number
             */
            const shared = freebiePath => {
                let ct = 0;

                for (let k in products) {
                    const freebiePaths = Object.values(products[k].freebies).map(v => v.path);

                    if (products[k].freebies && freebiePaths.includes(freebiePath)) {
                        ct++;
                    }
                }

                upgrades.forEach(upgrade => {
                    const freebiePaths = Object.values(upgrade.freebies).map(v => v.path);

                    if (upgrade.freebies && freebiePaths.includes(freebiePath)) {
                        ct++;
                    }
                })
                
                return ct;
            };

            removedProduct.freebies.forEach(v => {
                if (products[v.path] && !shared(v.path)) {
                    const removedFreebie = this.products[v.path];
                    delete this.products[v.path];

                    if (!removedSubFreebie) {
                        removedSubFreebie = removedFreebie.isSubscription;
                    }

                    this.trackCartRemove(removedFreebie, 0);
                }
            });
        }

        const wasPerpetual = removedProduct.isPerpetual && removedSubFreebie;
        const wasUpgrade = removedProduct.isUpgrade && removedSubFreebie;

        if (replaceFreebie && !this.cartContainsSub && (removedProduct.isSubscription || removedProduct.isTrial || wasPerpetual || wasUpgrade)) {
            const nextBestFreebieSub = this.getBestFreebieSub();

            if (Array.isArray(nextBestFreebieSub)) {
                store.commit('setFreebieModalVisibilityState', nextBestFreebieSub.filter(v => v.choice));
            }
        }
    }

    /**
     * Handles clicking a button to add a product to the cart.
     * @param {string} btnFsPath 
     * @param {boolean} suppressErrorNotif
     */
    handleAddToCartBtnClick (btnFsPath, suppressErrorNotif = false) {
        let cartProduct = null;

        try {
            cartProduct = new CartProduct(btnFsPath);
        } catch (e) {
            if (!suppressErrorNotif) {
                store.commit('setCartNotif', { msg: "Product not found.", type: 'error' });
            }

            throw new Error('Product not found.');
        }

        this.addFreebies(cartProduct.freebies, { path: btnFsPath, clickToCheckout: true });
    }

    /**
     * TODO: DOCUMENTATION NEEDED
     * @param {string} btnFsPath 
     * @param {object} fsDataObj 
     * @param {boolean} suppressErrorNotif 
     * @returns 
     */
    handleUpgradeCtaClick (btnFsPath, suppressErrorNotif = false) {
        let cartProduct = null;

        try {
            cartProduct = new CartProduct(btnFsPath);
        } catch (e) {
            if (!suppressErrorNotif) {
                store.commit('setCartNotif', { msg: "Product not found.", type: 'error' });
            }

            throw new Error('Product not found.');
        }

        const user = store.getters.getUserInstance;

        if (!(user?.id)) {
            // User is not signed in; open Auth Modal & setup continuation after successful login
            store.commit('setEventBusCallbackFunction', {
                name: "AuthModalRequest",
                cb: (fns) => {
                    if (typeof fns.addCallback === "function") {
                        fns.addCallback(() => {
                            this.handleUpgradeCtaClick(btnFsPath);
                        }, true);
                    }
                }
            });

            return;
        }
        
        /**
         * Attempt to grab the destination product
         */
        const fullProduct = Object.values(store.getters.getFsData).find((value) => {
            return value.pid === cartProduct.pid && value.name === cartProduct.variant_parent_product;
        });

        if (!fullProduct) {
            throw new Error("Upgrade is misconfigured or target product is no longer available");
        }

        // Simplified Product Summary retrieved as part of signing in
        /**
         * Simplified Purchase Entry: 
         * {
         *      availableUpgrades
         *      pr_authentication_string
         *      pr_pid
         *      pr_purchaseid
         *      pr_purchaseid_upg_to
         *      pr_serial_number
         *      sr_product
         * }
         */
        const products = user.summary?.productSummary || [];

        const checkProducts = products => {
            const qualifying_product = products.find(product => {
                const wasUpgraded = product.pr_purchaseid_upg_to !== undefined && product.pr_purchaseid_upg_to !== 0
                const availUpgrades = product.availableUpgrades;

                if (!availUpgrades?.length || wasUpgraded) {
                    return false;
                }

                return !!availUpgrades.find(upgrade => upgrade.ug_fs_path === cartProduct.path);
            });

            if (qualifying_product) {
                if (!this.upgradesInCart[qualifying_product.pr_authentication_string]) {
                    this.addUpgrade(qualifying_product, btnFsPath);
                    store.commit('setSendUserToCheckout');
                } else {
                    store.commit('setCartNotif', { msg: "Upgrade is already in the cart", type: 'info' });
                }

                return true;
            }

            // ATC Integration not supported yet;
            // TODO: revisit this in later ticket once ATC team is able to support this
            const owns_matching_product = products.find (product => product.pr_pid === cartProduct.pid || product.sr_product === fullProduct.name);

            if (owns_matching_product) {
                store.commit('setCartDialog', {
                    visible: true,
                    type: "owned",
                    data: fullProduct
                });

                return true;
            }

            return false;
        }

        if (products?.length && checkProducts(products)) {
            return;
        }

        store.commit("setCartDialog", {
            visible: true,
            type: "unqualified",
            data: fullProduct
        });
    }

    /**
     * Sets the cart's visibility state
     * @param {boolean} state
     */
    setVisibility (state) {
        this.visible = state;

        store.commit('setCart', this);
    }

    /**
     * Toggles the cart's visibility state
     */
    toggleVisibility () {
        if (fsLoading.value) {
            return;
        }

        this.visible = !this.visible;

        if (this.visible) {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                event: 'view_cart',
                ecommerce: {
                    items: this.getProductTrackingMapping(),
                },
            });
        }

        store.commit('setCart', this);
    }

    getProductTrackingMapping () {
        let productMapping = [];

        Object.keys(this.products).forEach(key => productMapping.push({
            item_id: this.products[key].path,
            item_name: this.products[key].name,
            price: this.products[key].isTrial ? 0 : this.products[key].productFinalPrice,
            quantity: this.products[key].quantity
        }));

        return productMapping;
    }

    /**
     * Adds a product to the cart. If the user has used their trial, and the product being added is a trial that is not a promo,
     * then an exception is thrown preventing the user from adding this to their cart.
     * @param {string} path 
     */
    addProduct ({ path, is_freebie = false }) {
        let product = null;

        try {
            product = new CartProduct(path, { quantity: 0, is_freebie });
        } catch (e) {
            store.commit('setCartNotif', { msg: "Product not found.", type: 'error' });

            throw new Error('Product not found.');
        }

        const user = store.getters.getUserInstance;

        if (product.isTrial && !product.prm && user?.id && !user?.hasAvailableTrial) {
            store.commit('setCartNotif', { msg: "You have already used your trial.", type: 'error' });

            throw new Error('User has already used their trial');
        }

        if (typeof this.products[path]?.quantity === 'number' && !(product.isSubscription || product.isTrial)) {
            this.setQuantity({ qty: this.products[path].quantity + 1, path });
        } else {
            if (product.isSubscription || product.isTrial) {
                for (let key in this.products) {
                    if (this.products[key].isTrial || this.products[key].isSubscription) {
                        this.removeProduct(this.products[key].path, false);
                        // delete this.products[key];
                    }
                }
            }
            
            this.products[path] = product;
            this.setQuantity({ qty: 1, path });
        }

        // Navigate to Checkout regardless of product type
        store.commit('setSendUserToCheckout');
        
        return this.products;
    }

    /**
     * Calls methods to total the cart's items as well as calculate its subtotal, the commits this instance of the cart
     * to the Vuex store, allowing it to persist through refreshes.
     */
    updateCart () {
        this.totalCartItems();
        this.calculateSubtotal();
        store.commit('setCart', this);

        throttleFunction(() => this.postCartContents(this.products, this.upgrades), 2000);
    }

    /**
     * Returns a boolean indicating whether a sub is a freebie.
     * @param {object} freebies 
     * @returns {boolean}
     */
    isFreebieSub (freebies) {
        return Object.values(freebies).some(val => {
            try {
                const product = new Product(val.path);
                return product.isSubscription;
            } catch (e) {
                return false;
            }
        });
    }

    /**
     * Compare subscriptions against each other for sorting.
     * Currently considers interval & interval units.
     * 
     * TODO: Consider a designated property on FS Data object to define subscription tier
     * 
     * @param {Object} a FsData item a
     * @param {Object} b FsData item b
     * @returns {number} integer value reflecting a's relative position to b; negative number if a > b; 0 if a == b; positive number if a < b
     */
    compareSubs (a, b) {
        const tiers = [
            "unlimited",
            "producer",
            "essentials"
        ];

        if (!a) {
            return 1;
        } else if (!b) {
            return -1;
        }

        // Compare Tiers

        tiersSegment: if (a.url !== b.url) {
            let aSlug = a.url.split("/").filter((part) => part);
            let bSlug = b.url.split("/").filter((part) => part);

            aSlug = aSlug[aSlug.length - 1];
            bSlug = bSlug[bSlug.length - 1];

            const aInd = tiers.indexOf(aSlug);
            const bInd = tiers.indexOf(bSlug);

            if (aInd === bInd) {
                // Tiers match; go on to compare intervals
                break tiersSegment;
            }

            if (aInd < 0) return bInd;
            if (bInd < 0) return aInd;

            return aInd - bInd;
        }

        // Compare Intervals

        if (a.sub_interval_unit !== b.sub_interval_unit) {
            return a.sub_interval_unit === "y" ? -1 : 1;
        }

        return b.sub_interval - a.sub_interval;
    }

    /**
     * Compare freebies known to be subscriptions against each other.
     * Returns 0 immediately if it detects that the default choice paths are the same.
     * 
     * @param {Object} a 
     * @param {Object} b 
     * @returns {number} integer value reflecting a's relative position to b; negative number if a > b; 0 if a == b; positive number if a < b
     */
    compareFreebieSubs (a, b) {
        const aDefault = a.find((val) => val.is_default_value);
        const bDefault = b.find((val) => val.is_default_value);

        if (aDefault?.path === bDefault?.path) {
            return 0;
        }

        const bFsDataObj = getFsDataObj(store.getters.getFsData, bDefault.path);
        const aFsDataObj = getFsDataObj(store.getters.getFsData, aDefault.path);

        return this.compareSubs(aFsDataObj, bFsDataObj);
    }

    /**
     * TODO: DOCUMENTATION NEEDED
     * @returns {object|null}
     */
    getBestFreebieSub () {
        const freebies = [];

        for (const [path, cartProduct] of Object.entries(this.products)) {            
            if (this.checkForFreebies(cartProduct) && this.isFreebieSub(cartProduct.freebies)) {
                freebies.push(cartProduct.freebies);
            }
        }

        this.upgrades.forEach(cartProduct => {
            if (this.checkForFreebies(cartProduct) && this.isFreebieSub(cartProduct.freebies)) {
                freebies.push(cartProduct.freebies);
            }
        })

        const subs = freebies.filter((val, i, arr) => {
            return arr.indexOf(val) === i;
        }).sort((a, b) => this.compareFreebieSubs(a, b));

        return subs.length ? subs[0] : null;
    }

    /**
     * Checks if a product is in the cart
     * @param {string} path 
     * @returns {boolean}
     */
	isProductInCart (path) {
		if (this.products[path]) {
            return true;
        }
		
        return false;
	}

    /**
     * Checks if an upgrade is in the cart
     * @param {string} path 
     * @returns {boolean}
     */
    isUpgradeInCart (path) {
        return this.upgrades.some(cartProduct => cartProduct.path === path);
    }

    /**
     * Returns a boolean indicating whether the current product (fsDataObj) has freebies.
     * @param {CartProduct} cartProduct 
     * @returns {boolean}
     */
    checkForFreebies (cartProduct) {
        if (!cartProduct) {
            return false;
        }

        if (!Array.isArray(cartProduct.freebies)) {
            cartProduct.freebies = Object.values(cartProduct.freebies);
        }

        return cartProduct.freebies.length > 0;
    }

    /**
     * Adds an upgrade from the cart, then adds an upgrade entry in this.upgrades.
     * @param {object} source 
     * @param {string} upgradePath
     */
    addUpgrade (source, upgradePath) {
        if (!(source?.pr_authentication_string && source?.pr_purchaseid && source?.pr_serial_number)) {
            throw new Error('Unable to add upgrade to cart: missing key source object properties');
        }

        let cartProduct = null;
        
        try {
            const cartProductData = {
                quantity: 1,
                qualifying_product: {
                    code: source.pr_authentication_string,
                    purchase_id: source.pr_purchaseid,
                    serial: source.pr_serial_number
                }
            }
            cartProduct = new CartProduct(upgradePath, cartProductData);
        } catch (e) {
            throw new Error('Product not found');
        }

        this.upgrades.push(cartProduct);
        this.updateCart();
        this.trackCartAdd(cartProduct, 1);
        this.addFreebies(cartProduct.freebies);
    }

    /**
     * Removes a product from the cart
     * @param {string} path 
     * @param {boolean} replaceFreebie default true; allow re-adding of bundled subscription with any valid perpetual in the cart
     */
    removeProduct (path, replaceFreebie = false) {
        const { products, coupon } = this;

        if (!products.hasOwnProperty(path)) {
            return false;
        }

        const removedProduct = this.products[path];

        delete this.products[path];

        this.removeFreebies(removedProduct, replaceFreebie);

        if (coupon && // remove a coupon if there are no applicable products or if cart is empty
            coupon.products.length &&
            !Object.keys(products).some(v => coupon.products.includes(v)) ||
            !Object.keys(products).length) {
            this.coupon = null;
        }

        if (this.promoProducts.includes(path)) {
            this.removePromoProduct(path);
        }

        this.updateCart();

        return this.products;
    }

    /**
     * Removes an upgrade from the cart, then removes the upgrade entry in this.upgrades. Also checks whether there are any
     * freebies associated with the upgrade in the cart, and if so, removes them.
     * @param {CartProduct} cartProduct
     */
    removeUpgrade (cartProduct) {
        let removalIndex = null;
        const ug = this.upgrades.find((v, i) => {
            if (v.path === cartProduct.path && v.qualifying_product?.code === cartProduct.qualifying_product?.code) {
                removalIndex = i;

                return v;
            }

            return false;
        });

        if (typeof removalIndex === 'number' && removalIndex >= 0) {
            this.upgrades.splice(removalIndex, 1);
            this.trackCartRemove(cartProduct, 1);
        }

        this.removeFreebies(ug, true);

        if (this.promoProducts.includes(ug.path)) {
            this.removePromoProduct(ug.path);
        }
        
        this.updateCart();

        return this.upgrades;
    }

    /**
     * Compare product objects to check if the product details have changed.
     * Stringifies both objects and returns the string comparison.
     * 
     * If source is omitted, function will attempt to pull source data from fs_data.json using current's product path.
     * 
     * @param {Object} current - Product data object; typically retrieved from Cart
     * @returns Boolean
     */
    hasProductChanged (current) {
        if (!current) {
            return false;
        }

        const source = getFsDataObj(store.getters.getFsData, current.path);

        // No source found; product either does not exist or has been removed from fs_data.json
        if (!source) return true;

        try {
            return JSON.stringify(current) !== JSON.stringify(source);
        } catch (e) {
            console.warn("Encountered an issue in comparing products");
            return false;
        }
    }

    /**
     * Update the specified product with the latest data from fs_data.json.
     * 
     * Returns true if a critical key (price, type, prm, wp_only, freebie options) has been updated, false otherwise.
     * Also returns false if the specified product path is not in the cart.
     * 
     * @param { String } path 
     * @returns Boolean
     */
    updateProduct (path) {
        if (!this.products[path]) {
            return false;
        }

        const source = getFsDataObj(store.getters.getFsData, path);

        let oldVal = null;
        let newVal = null;
        let criticalUpdate = false;

        for (const [key, value] of Object.entries(source)) {
            oldVal = (this.products[path])[key];
            newVal = value;

            /* eslint-disable no-fallthrough */

            switch (key) {
                case "path":
                    break;
                case "freebies":
                    oldVal = Object.values(oldVal);
                    newVal = Object.values(newVal);

                    if (oldVal.length === newVal.length && oldVal.every((val, i) => val.path === newVal[i].path)) {
                        this.products[path][key] = value;
                        break;
                    }

                    oldVal.forEach((val) => {
                        const freebie = this.products[val.path];

                        if (freebie) {
                            this.removeProduct(val.path, false);
                        }
                    });

                    this.addFreebies(value);

                    criticalUpdate = true;
                    this.products[path][key] = value;

                    break;
                case "price":
                    // break omitted
                case "prm":
                    // break omitted
                case "type":
                    // break omitted
                case "wp_only":
                    criticalUpdate = criticalUpdate || oldVal !== newVal;
                    // break omitted
                default:
                    if (oldVal !== newVal) {
                        this.products[path][key] = value;
                    }
            }

            /* eslint-enable no-fallthrough */
        }

        this.updateCart();

        return criticalUpdate;
    }

    /**
     * Update the Upgrade's qualifying product data
     * 
     * @param {Object} source - new Source product for the Upgrade
     * @param {String} path - Upgrade Path
     * @param {String} code - Current source product license code associated with the Upgrade
     */
    updateUpgrade (source, path, code) {
        const data = getFsDataObj(store.getters.getFsData, path);

        if (!data) {
            throw new Error("Unable to update upgrade in the cart; product not found");
        }

        if (!(code && typeof code === "string")) {
            throw new Error("Unable to update upgrade in the cart; missing required code");
        }

        const upgradeIndex = this.upgrades.findIndex(ug => ug.qualifying_product.code === code && ug.path === path);
        const upgrade = this.upgrades[upgradeIndex];

        if (!upgrade) {
            throw new Error("Unable to update ugrade in the cart; upgrade not found");
        }

        // TODO: update product data in event upgrade fsdata changes

        // No new source provided; return
        if (!source) {
            this.updateCart();
            return;
        }
        
        if (!(source.pr_authentication_string && source.pr_purchaseid && source.pr_serial_number)) {
            throw new Error("Unable to update upgrade in the cart: missing key source object properties");
        }

        // Source matches existing configuration; no need to update qualifying product
        if (upgrade.qualifying_product.code === source.pr_authentication_string) {
            this.updateCart();
            return;
        }

        if (this.upgradesInCart[source.pr_authentication_string]) {
            throw new Error("Unable to update upgrade in the cart: target product record already has an upgrade in the cart");
        }

        const newData = {
            code: source.pr_authentication_string,
            purchase_id: source.pr_purchaseid,
            serial: source.pr_serial_number
        }

        this.upgrades[upgradeIndex].qualifying_product = newData;

        this.updateCart();
    }

    /**
     * Verify that all products and upgrades in the cart are still valid.
     * Removes invalid products & upgrades from the cart and notifies the user.
     * Will attempt to re-insert a product if the freebies associated with it have been changed.
     * TODO: review this
     */
    validateCart () {
        let cartUpdated = false;

        for (const [path, cartProduct] of Object.entries(this.products)) {
            const current = {...cartProduct};
            delete current.quantity;
            const source = getFsDataObj(store.getters.getFsData, path);

            // Product no longer available; remove product
            if (!source) {
                cartUpdated = true;
                this.removeProduct(path);
                continue;
            }

            // Update Product if a change has been detected
            if (this.hasProductChanged(current)) {
                cartUpdated = this.updateProduct(path) || cartUpdated;
            }
        }

        if (cartUpdated) {
            store.commit('setCartNotif', { msg: "The products in your cart have been updated", type: 'info' });
        }
    }

    /**
     * Sets the quantity for a given product in cart. If the quantity of a given product in this.products is zero,
     * removes that product from this.products. 
     * If the quantity of items in the cart for which the coupon applies is zero, removes the coupon.
     * @param {number} qty
     * @param {string} path
     */
    setQuantity ({ qty, path }) {
        const cartProduct = this.products[path];

        if (cartProduct.quantity < qty) {
            this.trackCartAdd(cartProduct, qty);
        } else {
            this.trackCartRemove(cartProduct, qty);
        }

        cartProduct.quantity = qty;

        if (cartProduct.quantity === 0) {
            this.removeProduct(path);
            return;
        }

        this.updateCart();
    }

    /**
    * Returns the total number of items in the cart
    */
    totalCartItems () {
        let total = 0;

        for (let p in this.products) {
            total += this.products[p].quantity;
        }

        total += this.upgrades.length;
        this.totalItems = total;
    }

    /**
    * Returns the cart subtotal
    */
    calculateSubtotal () {
        let subtotal = 0;
        let trialpricing = 0;
        let discount = 0;
        const { products, upgrades, coupon } = this;
        const couponIsCashOff = typeof coupon?.cash_off === 'number' && coupon.cash_off >= 1;
        const couponIsPercentDiscount = typeof coupon?.discount === 'number' && coupon.discount > 0 && coupon.discount <= 1;
        const couponAppliesToProducts = coupon?.products?.length;

        if (couponIsCashOff) {
            discount += coupon.cash_off;
        }

        const calculateDiscount = item => {
            // TODO: remove coupon.products.includes(item.path) - this would fail if the coupon applies to all products
            if (coupon.products.includes(item.path) && couponIsPercentDiscount) {
                return (item.productFinalPrice * item.quantity) * coupon.discount;
            }

            return 0;
        }

        for (let p in products) { // process products
            const product = products[p];

            if (product.isTrial) {
                if (product.path.includes("14d-free")) {
                    trialpricing += (product.productFinalPrice * product.quantity);
                }

                continue;
            }

            if (couponAppliesToProducts) {
                discount += calculateDiscount(product);
            }

            subtotal += (product.productFinalPrice * product.quantity);
        }

        if (couponAppliesToProducts) {
            upgrades.forEach(v => {
                discount += calculateDiscount(v);
            });
        }

        subtotal += upgrades.reduce((a , b) => a + b.productFinalPrice, 0);

        // Apply general percentage discount if coupon isn't restricted
        if (couponIsPercentDiscount && (coupon.products && coupon.products.length === 0)) {
            discount += subtotal * coupon.discount;
        }

        this.discount = discount;
        this.subtotal = (subtotal - discount) >= 0 ? (subtotal - discount) : 0;
        this.trialpricing = trialpricing;
    }

    /**
    * Setter for promo code
    * @param {string|null} code
    */
    setPromoCode (code) {
        this.promoCode = code;
        this.updateCart();
    }

    /**
    * Setter for offer tag
    * @param {string|null} tag
    */
    setOfferTag (tag) {
        this.offerTag = tag;
        this.updateCart();
    }

    /**
     * Sets the promotion products
     * @param {string} path 
     */
    setPromoProducts (path) {
        this.promoProducts.push(path);
    }

    /**
     * Removes a promotion product
     * @param {string} path 
     */
    removePromoProduct (path) {
        this.promoProducts = this.promoProducts.filter(v => v !== path);

        if (!this.promoProducts.length) {
            this.setPromoCode(null);
            this.setOfferTag(null);
        }
    }

    /**
    * Handles GA tracking for product added event
    * @param {CartProduct} cartProduct
    * @param {number} quantity
    */
    trackCartAdd (cartProduct, quantity) {
        window.dataLayer = window.dataLayer || [];

		window.dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
                items: [
                    {
                        item_id: cartProduct.path,
                        item_name: cartProduct.name,
                        price: cartProduct.isTrial ? 0 : cartProduct.productFinalPrice,
                        quantity: quantity,
                    }
                ]
            },
		});
    }

    /**
    * Handles GA tracking for product removed event
    * @param {CartProduct} cartProduct
    * @param {number} quantity
    */
    trackCartRemove (cartProduct, quantity) {
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
            event: 'remove_from_cart',
            ecommerce: {
            items: [
                    {
                        item_id: cartProduct.path,
                        item_name: cartProduct.name,
                        price: cartProduct.isTrial ? 0 : cartProduct.productFinalPrice,
                        quantity: quantity
                    }
                ]
            }
        });
    }

    /**
     * Applies a coupon to the products in the cart
     * @param {Coupon} coupon 
     */
    applyCoupon (coupon) {
        let paths = [...Object.keys(this.products), ...this.upgrades.map(v => v.path)];

        if ((coupon.products && coupon.products.length) && !paths.some(v => coupon.products.includes(v))) { // coupon is restricted to certain products, but no eligible products are in cart
            return false;
        }

        this.coupon = coupon;
        this.calculateSubtotal();
        store.commit('setCart', this);
        
        return true;
    }

    /**
     * Removes a coupon
     */
    removeCoupon () {
        this.coupon = null;
        this.calculateSubtotal();
        store.commit('setCart', this);
    }

    /**
     * Empties the cart
     */
    emptyCart () {
        this.products = {};
        this.upgrades = [];
        this.subtotal = 0;
        this.trialpricing = 0;
        this.totalItems = 0;
        this.visible = false;
        this.promoCode = null;
        this.offerTag = null;
        this.coupon = null;

        store.commit('setCart', this);
    }

    /**
     * Empties the Cart's recorded upgrades
     */
    clearUpgrades() {
        const upgrades = [...this.upgrades];

        upgrades.forEach(upgrade => {
            this.removeUpgrade(upgrade);
        });

        store.commit('setCart', this);
    }

    /**
     * Posts the cart contents to server
     * TODO: DOCUMENTATION NEEDED
     * TODO: REFACTOR
     * @param {object} products
     * @param {array} upgrades
     * @param {string} email
     * @param {string} cacheKey
     */
    async postCartContents (products, upgrades, email = null, cacheKey = null) {
        const user = store.getters.getUserInstance;

        if (!email && !user?.id) {
            return;
        }

        const extract = products => {
            return products.map((v, k) => {
                return (({ name, path, pid, quantity, thumbnail, url, currencyFormattedFinalPrice }) => {
                    return { name, path, pid, quantity, thumbnail, url, currencyFormattedFinalPrice };
                })(v);
            });
        };

        let payload = extract(Object.values(products));        
        payload = [...payload, ...extract(upgrades)];
        let path = '/laravel/rest/cart-updated';

        if (email && cacheKey && typeof email === 'string' && typeof cacheKey === 'string') {
            path = '/laravel/rest/cart-updated-ua';
            payload = {
                email,
                products: payload,
                cache_key: cacheKey,
            };
        }

        await (new HttpClient({ path, verify: false, global: true })).post(payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    /**
    * Returns the adjusted subtotal after a coupon has been applied
    */
    get formattedSubtotal () {
        return formatToCurrency(this.subtotal);
    }

    get formattedTrialPricing () {
        return formatToCurrency(this.trialpricing);
    }

    /**
    * Returns a boolean indicating whether the order contains an ATU 14 day trial
    */
    get cartContainsTrial () {
        return Object.values(this.products).some(p => p.isTrial);
    }

    /**
     * Returns a boolean indicating whether the cart contains any paid products
     */
    get cartContainsPaidProducts () {
        return Object.values(this.products).some(p => p.productFinalPrice > 0);
    }

    /**
    * Returns a boolean indicating whether the order contains a promo trial subscription such as Avid
    */
    get cartContainsPromo () {
        return Object.values(this.products).some(p => p.prm);
    }

    /**
    * Returns a boolean indicating whether the order contains ATU
    */
    get cartContainsAtu () {
        let paths = Object.keys(this.products);
        paths = [...paths, ...this.upgrades.map(v => v.path)];

        return paths.some(path => path.includes('auto-tune-unlimited'));
    }

    /**
     * Returns a boolean indicating that the cart contains a non-trial subscription
     */
    get cartContainsSub () {
        return Object.values(this.products).some(product => product.isSubscription);
    }

    /**
     * Returns a boolean indicating whether the cart is empty or not
     */
    get cartIsNotEmpty () {
        return !!(Object.keys(this.products).length || this.upgrades.length);
    }

    /**
     * Returns the fastspring path of each product in the cart
     */
    get paths () {
        let paths = Object.keys(this.products);

        return [...paths, ...this.upgrades.map(v => v.path)];
    }

    /**
     * Returns an object showing which upgrades are currently in the cart, with the product to upgrade's regcode as the key
     * and the upgrade's path as the value.
     */
    get upgradesInCart () {
        const upgrades = {};

        this.upgrades.forEach(v => {
            upgrades[v.qualifying_product.code] = v.path;
        });

        return upgrades;
    }
}