import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1f36feef"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "FAQ-header"
};
var _hoisted_2 = {
  class: "FAQ-body"
};
export default {
  props: {
    question: String
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      var _component_el_collapse_item = _resolveComponent("el-collapse-item");

      return _openBlock(), _createBlock(_component_el_collapse_item, {
        class: "FAQ-entry"
      }, {
        title: _withCtx(function () {
          return [_createElementVNode("h3", _hoisted_1, [_renderSlot(_ctx.$slots, "question", {}, function () {
            return [_createTextVNode(_toDisplayString(__props.question), 1)];
          })])];
        }),
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default")])];
        }),
        _: 3
      });
    };
  }
};