import store from '../store';
import { formatToCurrency } from '../helpers';

export default class Product {

    constructor (path) {
        if (!(path && typeof path === 'string')) {
            throw new TypeError("Invalid type passed for 'path' argument");
        }

        this.name = null;
        this.price = 0;
        this.base_price = 0;
        this.discount_rate = 0;
        this.path = '';
        this.thumbnail = null;
        this.type = null;
        this.prm = false;
        this.url = '';
        this.wp_only = null;
        this.upgradeable_to = [];
        this.switchable_to = [];
        this.downgradeable_to = [];
        this.checkout_info = null;
        this.freebies = null;
        this.pid = null;
        this.use_wibu = null;
        this.installer_path_win = null;
        this.installer_path_mac = null;
        this.special_text = '';
        this.sub_interval = null;
        this.sub_interval_unit = null;
        this.trial_interval = null;
        this.trial_interval_unit = null;
        this.subscription_line_item_addon = null;
        this.variant_parent_product = null;
        this.quantityDiscount = {};
        this.discountReason = null;
        this.discountPeriodCount = null;

        const fsData = store.getters.getFsData;
        const fsDataObject = Object.values(fsData).find(product => product.path === path);

        if (!(fsDataObject && typeof fsDataObject === 'object')) {
            throw new Error(`Unable to find matching product catalog entry for product with path ${path}`);
        }

        for (let prop in fsDataObject) {
            this[prop] = fsDataObject[prop];
        }
    }

    /**
     * Returns a boolean indicating whether the product is discounted
     * @returns {boolean}
     */
    get productIsDiscounted () {
        if (!(this.quantityDiscount && typeof this.quantityDiscount === 'object')) {
            return false;
        }

        const discounts = Object.values(this.quantityDiscount);

        if (discounts.length <= 0) {
            return false;
        }

        return discounts.some(discount => {
            return discount.discountPercent > 0 || discount.discountValue > 0;
        });
    }

    /**
     * Returns the product discount amount
     * @returns {number}
     */
    get productDiscountValue () {
        if (!this.productIsDiscounted) {
            return 0;
        }

        return Object.values(this.quantityDiscount)[0].discountValue;
    }

    /**
     * Returns the product discount percentage
     * @returns {number}
     */
    get productDiscountPercent () {
        if (!this.productIsDiscounted) {
            return 0;
        }

        return Object.values(this.quantityDiscount)[0].discountPercent;
    }

    /**
     * Returns the product's price, factoring whether the product has an active discount.
     * @returns {number|null}
     */
    get productFinalPrice () {
        if (this.productIsDiscounted) {
            return Object.values(this.quantityDiscount)[0].unitPrice;
        }

        return this.price;
    }

    /**
     * Returns the product's currency-formatted base price, factoring whether the product has an active discount.
     * @returns {string}
     */
    get currencyFormattedBasePrice () {
        return formatToCurrency(this.price);
    }

    /**
     * Returns the product's currency-formatted sale price, factoring whether the product has an active discount.
     * @returns {string}
     */
    get currencyFormattedFinalPrice () {
        return formatToCurrency(this.productFinalPrice);
    }

    /**
     * Returns a boolean indicating whether this product is a trial.
     * @returns {bool}
     */
    get isTrial () {
        return this.type === 'trial';
    }

    /**
     * Returns a boolean indicating whether this product is a trial.
     * @returns {bool}
     */
    get isSubscription () {
        return this.type === 'subscription';
    }

    /**
     * Returns a boolean indicating whether this product is a monthly subscription
     * @returns {bool}
     */
    get isMonthlySubscription () {
        return this.isSubscription && this.sub_interval === 1 && this.sub_interval_unit === 'm';
    }

    /**
     * Returns a boolean indicating whether this product is an annual subscription
     * @returns {bool}
     */
    get isAnnualSubscription () {
        return this.isSubscription && this.sub_interval === 1 && this.sub_interval_unit === 'y';
    }

    /**
     * Returns a boolean indicating whether this product is a perpetual product.
     * @returns {bool}
     */
    get isPerpetual () {
        return this.type === 'perpetual';
    }

    /**
     * Returns a boolean indicating whether this product is an upgrade.
     * @returns {bool}
     */
    get isUpgrade () {
        return this.type === 'upgrade';
    }

    /**
     * Returns the "monthly" rate of the base price of an annual subscription.
     * @returns {string|null}
     */
    get currencyFormattedAnnualSubscriptionMonthlyBaseRate () {
        if (!this.isAnnualSubscription) {
            return null;
        }

        return formatToCurrency(this.productFinalPrice / 12);
    }

    /**
     * Returns the "monthly" rate of the final price of an annual subscription.
     * @returns {string|null}
     */
    get currencyFormattedAnnualSubscriptionMonthlyFinalRate () {
        if (!this.isAnnualSubscription) {
            return null;
        }

        return formatToCurrency(this.productFinalPrice / 12);
    }

    /**
     * Returns a boolean indicating whether this product has a trial.
     * @returns {boolean}
     */
    get hasTrial () {
        if (!this.isSubscription) {
            return true;
        }

        const potentialTrialPath = this.path.replace("annual", "monthly") + "-14d-free";

        return !!store.getters.getFsDataObj(potentialTrialPath);
    }
}